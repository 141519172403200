import React from 'react';
import emailjs from 'emailjs-com';
import { Container, Col, Row } from 'react-bootstrap';

export default function ContactUs() {
    const sendEmail = (e: any) => {
        e.preventDefault();
        emailjs.sendForm('service_1giyni7', 'template_7fvqheh', e.target, 'wo4DXVWiHaAo6GEVy')
            .then((result) => {
                window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <Container>
            <Row className='content' id='contato'>
                <Row className='title'>
                    <h1>Entre em contato conosco</h1>
                </Row>
                <Col className='form'>
                    <form className="contact-form" onSubmit={sendEmail}>
                        <Col>
                            <Row>
                                <input type="text" className='input' placeholder="Nome" name="from_name"></input>
                            </Row>
                            <Row>
                                <input type="text" className='input' placeholder="Email" name="from_email"></input>
                            </Row>
                            <Row>
                                <input type="text" className='input' placeholder="Telefone" name="from_telefone"></input>
                            </Row>
                            <Row>
                                <textarea className="text-area" placeholder="Mensagem" name="html_message"
                                    rows={8} cols={88}>
                                </textarea>
                            </Row>
                            <Row>
                                <input type="submit" value={'Enviar'} className='form-submit' />
                            </Row>
                        </Col>

                    </form>
                </Col>
            </Row>
        </Container>

    );
};