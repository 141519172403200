import { Row } from 'react-bootstrap';
import FloatingWhatsApp from '../assets/whatsapp-icon.png';

function FloatWhatsApp() {
    return (
        <Row>
            <div className='float'>
                <a href="https://api.whatsapp.com/send?phone=+5515988159280" target="_blank">
                    <img
                        src={FloatingWhatsApp}
                        width="64"
                        height="64"
                    />
                </a>
            </div>
        </Row>
    );
}

export default FloatWhatsApp