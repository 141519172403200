import { Navbar, Container, Nav, Col, Row } from 'react-bootstrap';
import logo from '../assets/Logo.png';
import telIcon from '../assets/telephone-icon.png';
import emailIcon from '../assets/email-icon.png';
import { useMediaQuery } from 'react-responsive';
import { Context as ResponsiveContext } from 'react-responsive';

function Footer() {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 767 })
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return (
        <Container className='content-footer'>
            {isDesktop && <ResponsiveContext.Provider value={{ width: 1024 }}>
                <Row className='content'>
                    <Col>
                        <Navbar.Brand href="#home">
                            <img
                                alt="logo"
                                src={logo}
                                width="256"
                                height="172.91"

                            />
                        </Navbar.Brand>
                    </Col>
                    <Col>
                        <Container className='footer'>
                            <Row>
                                <div>
                                    <a href="https://api.whatsapp.com/send?phone=+5515988159280" target="_blank">

                                        <img
                                            alt="telephone"
                                            src={telIcon}
                                            width="64"
                                            height="64"
                                            className="d-inline-block align-top"
                                        />
                                        <h3>(15) 988159280</h3>
                                    </a>
                                </div>
                            </Row>
                            <Row>
                                <div className='linha-div'></div>
                            </Row>
                            <Row>
                                <div className='img-block'>
                                    <img
                                        alt="email"
                                        src={emailIcon}
                                        width="64"
                                        height="64"
                                        className="d-inline-block align-top"
                                    />
                                    <h3>contato@slconstrucoes.com.br</h3>
                                </div>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </ResponsiveContext.Provider>}
            {isTabletOrMobile && <ResponsiveContext.Provider value={{ width: 320 }}>
                <Col>
                    <Container className='footer'>
                        <Row>
                            <div>
                                <a href="https://api.whatsapp.com/send?phone=+5515988159280" target="_blank">

                                    <img
                                        alt="telephone"
                                        src={telIcon}
                                        width="32"
                                        height="32"
                                        className="d-inline-block align-top"
                                    />
                                    <p>(15) 988159280</p>
                                </a>
                            </div>
                        </Row>
                        <Row>
                            <div className='linha-div'></div>
                        </Row>
                        <Row>
                            <div className='img-block'>
                                <img
                                    alt="email"
                                    src={emailIcon}
                                    width="32"
                                    height="32"
                                    className="d-inline-block align-top"
                                />
                                <p>contato@slconstrucoes.com.br</p>
                            </div>
                        </Row>
                    </Container>
                </Col>
            </ResponsiveContext.Provider>}
        </Container>
    );
}

export default Footer;