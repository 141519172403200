import { Navbar, Container, Nav } from 'react-bootstrap';
import logo from '../assets/Logo.png';
import { useMediaQuery } from 'react-responsive';
import { Context as ResponsiveContext } from 'react-responsive';      

function Header() {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 767 })
    const isDesktop = useMediaQuery({ minWidth: 768 }) 
  return (
    <Navbar className='header'>
      {isDesktop && <ResponsiveContext.Provider value={{ width: 1024 }}>
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt="logo"
              src={logo}
              width="130"
              height="87.81"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
        </Container>
      </ResponsiveContext.Provider>}

      {isTabletOrMobile && <ResponsiveContext.Provider value={{ width: 1024 }}>
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt="logo"
              src={logo}
              width="64"
              height="43.23"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
        </Container>
      </ResponsiveContext.Provider>}

      <Nav className="me-auto">
        <Nav.Link className='header-item' href="#home">Home</Nav.Link>
        <Nav.Link className='header-item' href="#servico">Serviços</Nav.Link>
        <Nav.Link className='header-item' href="#contato">Contato</Nav.Link>
      </Nav>
    </Navbar>    
  );
}
export default Header;