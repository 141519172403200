
import sendEmail from './sendEmail';
import { useMediaQuery } from 'react-responsive';
import { Context as ResponsiveContext } from 'react-responsive';
import Header from './Header';
import Body from './Body';
import FloatWhatsApp from './FloatWhatsApp';
import Footer from './Footer';

function Main() {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
    const isDesktop = useMediaQuery({ minWidth: 1024 })
    return (
        <div className="layout-main">
            {isTabletOrMobile && <ResponsiveContext.Provider value={{ width: 320 }}> 
                {Header()}
                {Body()}
                {sendEmail()}
                {FloatWhatsApp()}
                {Footer()}
            </ResponsiveContext.Provider>}
            {isDesktop && <ResponsiveContext.Provider value={{ width: 1024 }}> 
                {Header()}
                {Body()}
                {sendEmail()}
                {FloatWhatsApp()}
                {Footer()}
            </ResponsiveContext.Provider>}
        </div >
    );
}

export default Main
