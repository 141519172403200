import { Container, Col, Row } from 'react-bootstrap';
import apresentationString from '../assets/apresentation.json'
import paperIcon from '../assets/paper-icon.png';
import condominioIcon from '../assets/condominion-icon.png';
import paperIconBlue from '../assets/paper-icon-blue.png';
import condominioIconBlue from '../assets/condominion-icon-blue.png';
import casa from '../assets/casa.png';
import casaBlue from '../assets/casa-blue.png';
import floor from '../assets/floor.png';
import floorBlue from '../assets/floor-blue.png';
import piscina from '../assets/swimming-pool.png';
import piscinaBlue from '../assets/swimming-pool-blue.png';
import infra from '../assets/a-infraestrutura.png';
import infraBlue from '../assets/a-infraestrutura-blue.png';
import building from '../assets/building.png'
import buildingBlue from '../assets/building-blue.png'
import { useMediaQuery } from 'react-responsive';
import { Context as ResponsiveContext } from 'react-responsive';

function Body() {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 767 })
    const isDesktop = useMediaQuery({ minWidth: 768 })

    return (
        <Container>
            <Row className='content' id='home'>
                <Col className='paper-icon'>
                    <div className='home-icon'>
                        <div className='flipper'>
                            {isDesktop && <ResponsiveContext.Provider value={{ width: 1024 }}>
                                <div className='front-main'>
                                    <img
                                        src={paperIcon}
                                        alt="planejamento"
                                        width="300"
                                        height="319.71"
                                        className="d-inline-block align-top"
                                        />
                                </div>
                                <div className='back-main'>
                                    <img
                                        src={paperIconBlue}
                                        alt="planejamento"
                                        width="300"
                                        height="319.71"
                                        className="d-inline-block align-top"
                                        />
                                </div>
                            </ResponsiveContext.Provider>}

                            {isTabletOrMobile && <ResponsiveContext.Provider value={{ width: 320 }}>
                                <div className='front-main'>
                                    <img
                                        src={paperIcon}
                                        alt="planejamento"
                                        width="128"
                                        height="136.41"
                                        className="d-inline-block align-top"
                                        />
                                </div>
                                <div className='back-main'>
                                    <img
                                        src={paperIconBlue}
                                        alt="planejamento"
                                        width="128"
                                        height="136.41"
                                        className="d-inline-block align-top"
                                        />
                                </div>
                            </ResponsiveContext.Provider>}
                        </div>
                    </div>
                </Col>
                <Col className='home-text'>
                    <Row>
                        {isDesktop && <ResponsiveContext.Provider value={{ width: 1024 }}>
                            <h1>SL Construções</h1>
                        </ResponsiveContext.Provider>}
                        {isTabletOrMobile && <ResponsiveContext.Provider value={{ width: 320 }}>
                            <h2>SL Construções</h2>
                        </ResponsiveContext.Provider>}
                    </Row>
                    <Row>
                        <p>{apresentationString.apresentation}</p>
                    </Row>
                </Col>
            </Row>
            <Row className='content' id='servico'>
                <Row className='title'>
                    <h1>Serviços</h1>
                </Row>
                <Col>
                    <div className='icons'>
                        <h3 className='subtitle'>Residenciais</h3>
                        <div className='flipper'>
                            <div className='front'>
                                <img
                                    src={casa}
                                    alt="residencial"
                                    width="256"
                                    height="256"
                                    className="d-inline-block align-top"
                                />
                                <p>Construção</p>
                                <p>Ampliação</p>
                                <p>Reforma</p>
                                <p>Construção de Garagem</p>
                            </div>
                            <div className='back'>
                                <img
                                    src={casaBlue}
                                    alt="residencial"
                                    width="256"
                                    height="256"
                                    className="d-inline-block align-top"
                                />
                                <p>Construção</p>
                                <p>Ampliação</p>
                                <p>Reforma</p>
                                <p>Construção de Garagem</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='icons'>
                        <h3 className='subtitle'>Edifícios</h3>
                        <div className='flipper'>
                            <div className='front'>
                                <img
                                    src={building}
                                    alt="residencial"
                                    width="256"
                                    height="256"
                                    className="d-inline-block align-top"
                                />
                                <p>Construção Comercial</p>
                                <p>Construção Residencial</p>
                                <p>Reforma</p>
                            </div>
                            <div className='back'>
                                <img
                                    src={buildingBlue}
                                    alt="residencial"
                                    width="256"
                                    height="256"
                                    className="d-inline-block align-top"
                                />
                                <p>Construção Comercial</p>
                                <p>Construção Residencial</p>
                                <p>Reforma</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='icons'>
                        <h3 className='subtitle'>Condomínios</h3>
                        <div className='flipper'>
                            <div className='front'>
                                <img
                                    src={condominioIcon}
                                    alt="residencial"
                                    width="256"
                                    height="256"
                                    className="d-inline-block align-top"
                                />
                                <p>Reforma</p>
                            </div>
                            <div className='back'>
                                <img
                                    src={condominioIconBlue}
                                    alt="residencial"
                                    width="256"
                                    height="256"
                                    className="d-inline-block align-top"
                                />
                                <p>Reforma</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='icons'>
                        <h3 className='subtitle'>Piscinas</h3>
                        <div className='flipper'>
                            <div className='front'>
                                <img
                                    src={piscina}
                                    alt="residencial"
                                    width="256"
                                    height="256"
                                    className="d-inline-block align-top"
                                />
                                <p>Construção</p>
                                <p>Reforma</p>
                                <p>Concreto Armado Estrutural</p>
                            </div>
                            <div className='back'>
                                <img
                                    src={piscinaBlue}
                                    alt="residencial"
                                    width="256"
                                    height="256"
                                    className="d-inline-block align-top"
                                />
                                <p>Construção</p>
                                <p>Reforma</p>
                                <p>Concreto Armado Estrutural</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='icons'>
                        <h3 className='subtitle'>Pisos</h3>
                        <div className='flipper'>
                            <div className='front'>
                                <img
                                    src={floor}
                                    alt="residencial"
                                    width="256"
                                    height="256"
                                    className="d-inline-block align-top"
                                />
                                <p>Piso em Concreto</p>
                                <p>Armado Simples</p>
                                <p>Assoalho Piso Laminado</p>
                                <p>Assoalho</p>
                            </div>
                            <div className='back'>
                                <img
                                    src={floorBlue}
                                    alt="residencial"
                                    width="256"
                                    height="256"
                                    className="d-inline-block align-top"
                                />
                                <p>Piso em Concreto</p>
                                <p>Armado Simples</p>
                                <p>Assoalho Piso Laminado</p>
                                <p>Assoalho</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='icons'>
                        <h3 className='subtitle'>Infraestrutura</h3>
                        <div className='flipper'>
                            <div className='front'>
                                <img
                                    src={infra}
                                    alt="residencial"
                                    width="256"
                                    height="256"
                                    className="d-inline-block align-top"
                                />
                            </div>
                            <div className='back'>
                                <img
                                    src={infraBlue}
                                    alt="residencial"
                                    width="256"
                                    height="256"
                                    className="d-inline-block align-top"
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Body;