import React, {useState, useEffect} from 'react';
import Main from './view/main';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/main.css'

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
